:root {
  --s-textcolor: #232935;
  --s-sitecolor: #0AD705;
  --s-linkcolor: #232935;
  --s-linkcolor-hov: #0AD705;
  --s-btn-border: #232935;
  --s-btn-bg: #F4F4F4;
  --s-btn-bg-hov: #232935;
  --s-btn-txt: #232935;
  --s-btn-txt-hov: #F4F4F4;
  --s-getintouch-icon: url(/images/icon-mail-greyblue.svg);
  --s-getintouch-icon-hov: url(/images/icon-mail-green.svg);
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  border: 3px solid var(--s-btn-border);
  background-color: var(--s-btn-bg);
  line-height: 1.3;
  cursor: pointer;
  color: var(--s-btn-txt);
  padding: 5px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    color: var(--s-btn-txt-hov);
    background-color: var(--s-btn-bg-hov);
  }
  .button:active {
    background-color: var(--s-btn-bg-hov);
  }
}
.navigation div.navi {
  float: left;
}
.navigation div.navi > .item {
  float: left;
}
.navigation div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1280px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub1 {
  margin-right: 60px;
  --s-linkcolor: #232935;
  --s-linkcolor-hov: #232935;
}
.navigation div.sub1 > .item {
  margin: 0 30px;
}
.navigation div.sub1 > .item.init {
  margin-left: 0;
}
.navigation div.sub1 > .item.exit {
  margin-right: 0;
}
.navigation div.sub1 > .item.cb-navi-target-layout8 > .menu {
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, #006668 0%, #0AD705 100%);
  padding: 8px 9px 9px;
  box-sizing: border-box;
  --s-linkcolor: #F4F4F4;
}
.navigation div.sub1 > .item > .menu {
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  position: relative;
  padding: 8px 0;
  text-decoration: unset;
}
.navigation div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 0;
  height: 3px;
  background-color: var(--s-linkcolor);
  transition: all 0.3s;
}
.navigation div.sub1 > .item > .menu:hover:after,
.navigation div.sub1 > .item > .menu:focus:after,
.navigation div.sub1 > .item > .menu.path:after {
  width: 100%;
}
.footlogo,
.cb-home,
#home {
  height: 58px;
}
.container--job {
  flex-direction: row;
  box-sizing: border-box;
}
.container--job .mood {
  width: 43.90625%;
  max-height: 1030px;
  position: relative;
  aspect-ratio: 0.8;
}
@supports not (aspect-ratio: 4 /  5) {
  .container--job .mood:before {
    float: left;
    padding-top: 125%;
    content: '';
  }
  .container--job .mood:after {
    display: block;
    content: '';
    clear: both;
  }
}
.container--job .content {
  width: 56.09375%;
  padding-right: 5.20833333%;
}
.footarea {
  width: 101.63043478%;
  margin-left: -0.80213904%;
  display: flex;
  flex-wrap: wrap;
}
.footpart {
  margin-left: 0.80213904%;
  margin-right: 0.80213904%;
}
.footpart.footpart--slim {
  width: 13.90374332%;
}
.footpart.footpart--half {
  width: 20.85561497%;
}
.footpart.footpart--wide {
  width: 98.39572193%;
}
.wrapper {
  font-size: 18px;
  line-height: 1.44444444;
}
.area--one {
  width: 101.51515152%;
  margin-left: -0.75757576%;
}
.area--intro {
  width: 101.51515152%;
  margin-left: -0.75757576%;
}
.area--column {
  width: 50.75757576%;
  margin-left: -0.75757576%;
}
.area--offer {
  width: 50.75757576%;
  margin-left: -0.75757576%;
}
.area--intro .unit {
  width: 98.50746269%;
  margin-left: 0.74626866%;
  margin-right: 0.74626866%;
  padding-right: 29.85074627%;
  box-sizing: border-box;
}
.area--one .unit {
  width: 98.50746269%;
  margin-left: 0.74626866%;
  margin-right: 0.74626866%;
}
.area--one .unitOne--1-1 .part.text {
  padding-right: 17.91044776%;
  box-sizing: border-box;
}
.area--one .unitOne--1-1-indent {
  padding-right: 50%;
  box-sizing: border-box;
}
.area--one .unitOne--1-1-indentBoth {
  padding: 0 16.04477612%;
  box-sizing: border-box;
}
.area--one .unitOne--1-2 {
  width: 48.50746269%;
}
.area--one .unitOne--1-2 .part:not(.pict) {
  width: 66.15384615%;
  margin-left: 16.92307692%;
  margin-right: 16.92307692%;
}
.area--one .unitOne--1-2-indentRight {
  width: 48.50746269%;
}
.area--one .unitOne--1-2-indentRight .part:not(.pict) {
  width: 83.07692308%;
  margin-right: 16.92307692%;
}
.area--one .unitOne--1-3 {
  width: 31.79104478%;
}
.area--one .unitOne--2-3 {
  width: 65.2238806%;
}
.area--one .unitOne--2-3 .part:not(.pict) {
  width: 74.82837529%;
  margin-left: 12.58581236%;
  margin-right: 12.58581236%;
}
.area--one .unitOne--1-4 {
  width: 23.50746269%;
}
.area--one .unitThree {
  width: 31.79104478%;
}
.area--one .unitThree .unit__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.area--one .unitThree .part.text {
  width: 60%;
  margin-right: auto;
}
@media (hover: hover) and (pointer: fine) {
  #view .area--one .unitThree .unit__background {
    overflow: hidden;
  }
  #view .area--one .unitThree .unit__backgroundImage {
    transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }
  #view .area--one .unitThree .unit__content {
    transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
    opacity: 0;
    transform: translateY(-100%);
  }
  #view .area--one .unitThree:hover .unit__backgroundImage,
  #view .area--one .unitThree:focus .unit__backgroundImage {
    transform: scale(1.1);
  }
  #view .area--one .unitThree:hover .unit__content,
  #view .area--one .unitThree:focus .unit__content {
    opacity: 1;
    transform: translateY(0);
  }
}
#view .area--one .unitTwo .unit__content {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  opacity: 0;
}
@media (hover: hover) and (pointer: fine) {
  #view .area--one .unitTwo:hover .unit__content,
  #view .area--one .unitTwo:focus .unit__content {
    opacity: 1;
  }
}
.area--column .unit {
  width: 97.01492537%;
  margin-left: 1.49253731%;
  margin-right: 1.49253731%;
}
.area--column .unitOne--1-1-indentLeft {
  padding-left: 32.53731343%;
  box-sizing: border-box;
}
.area--column .unitOne--1-2 {
  width: 47.01492537%;
}
.area--offer .unit {
  width: 97.01492537%;
  margin-left: 1.49253731%;
  margin-right: 1.49253731%;
}
.area--job .unit .part--slim {
  width: 50%;
}
/*# sourceMappingURL=./screen-large.css.map */